import React, { FC } from "react";
import PageContainer from "../components/custom/PageContainer";
import {Link} from 'react-router-dom'
import Main from "../components/custom/Main";
const NotFoundPage:FC = () =>{
    return(
        <Main>
        <PageContainer title=""> 
            <div className="error-page">
                <h2 className="headline text-warning"> 404</h2>

                <div className="error-content">
                <h3><i className="fas fa-exclamation-triangle text-warning"></i> Oops! Page not found.</h3>
                <p>
                    We could not find the page you were looking for.
                    Meanwhile, you may <Link to="/">return to dashboard</Link> or try using the search form.
                </p>
                </div>    
            </div>
        </PageContainer>
        </Main>
    )
}
export default NotFoundPage;