import { Dispatch } from "redux"
import api, { APIResponse, getAxiosRequestConfig } from "../../config/api"
import { GetState } from "../reducers"

export enum DropDownActionList {
    ADD_DROPDOWN = 'ADD_DROPDOWN',
    FETCH_DROPDOWN = 'FETCH_DROPDOWN',
    UPDATE_DROPDOWN = 'UPDATE_DROPDOWN',
    DELETE_DROPDOWN = 'DELETE_DROPDOWN',
}

export interface DropDownType {
    id?: number
    category: string
    title: string
}

export interface AddDropDownAction {
    type: DropDownActionList.ADD_DROPDOWN
    data: DropDownType
}

export interface FetchDropDownAction {
    type: DropDownActionList.FETCH_DROPDOWN
    data: Array<DropDownType>
}

export interface UpdateDropDownAction {
    type: DropDownActionList.UPDATE_DROPDOWN
    data: DropDownType
}

export interface DeleteDropDownAction {
    type: DropDownActionList.DELETE_DROPDOWN
    data: number
}

export type DropDownActions = AddDropDownAction | FetchDropDownAction | UpdateDropDownAction | DeleteDropDownAction

export const addDropDown = (data: DropDownType) => {
    return async (dispatch: Dispatch, getState: GetState) => {
        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        return api.post<APIResponse<DropDownType>>('dropdownMaster/', data, config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<AddDropDownAction>({
                    type: DropDownActionList.ADD_DROPDOWN,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'DropDown Added')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to add'
                : 'Unable to add')
        })
    }
}

export const updateDropDown = (data: DropDownType, id: number) => {
    return async (dispatch: Dispatch, getState: GetState) => {
        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        return api.put<APIResponse<DropDownType>>(`dropdownMaster/?id=${id}`, data, config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<UpdateDropDownAction>({
                    type: DropDownActionList.UPDATE_DROPDOWN,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'DropDOwn Updated')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to update'
                : 'Unable to update')
        })
    }
}

export const fetchDropDown = () => {
    return async (dispatch: Dispatch, getState: GetState) => {
        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        return api.get<APIResponse<DropDownType[]>>('dropdownMaster/', config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<FetchDropDownAction>({
                    type: DropDownActionList.FETCH_DROPDOWN,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Fetch Successfull')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to fetch'
                : 'Unable to fetch')
        })
    }
}

export const deleteDropDown = (id: number) => {
    return async (dispatch: Dispatch, getState: GetState) => {
        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        return api.delete<APIResponse<DropDownType>>(`dropdownMaster/?id=${id}`, config).then(response => {
            if (response.status === 200) {
                dispatch<DeleteDropDownAction>({
                    type: DropDownActionList.DELETE_DROPDOWN,
                    data: id
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'DropDown Deleted')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to delete'
                : 'Unable to delete')
        })
    }
}
