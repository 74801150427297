/* eslint-disable no-throw-literal */
import { Dispatch } from "redux"
import api, { APIResponse, errorHandler } from "../../config/api"

export interface LoginDataType {
    userId: string
    password: string
}

export interface RolePermissionType {
    pageTitle?: string | null
    pageUrl?: string | null
    add?: boolean | null
    edit?: boolean | null
    view?: boolean | null
    delete?: boolean | null
}

export interface AuthUserType {
    userId: string
    token?: string
    password?: string
    status?: string
    username: string
    name: string
    mobileNumber: string
    branchId: number
    email: string
    role: string
    designation?: string
    expireAt?: number
    rolePermissions?: RolePermissionType[]  
}

export enum AuthUserActionList {
    LOGIN_USER = 'LOGIN_USER',
    LOGOUT_USER = 'LOGOUT_USER',
    SIGNUP_USER = 'SIGNUP_USER'
}

export interface SignupAction {
    type: AuthUserActionList.SIGNUP_USER
    data: AuthUserType
}

export interface LoginAction {
    type: AuthUserActionList.LOGIN_USER
    data: AuthUserType
}

export interface LogoutAction {
    type: AuthUserActionList.LOGOUT_USER
    data: null
}

export type AuthUserActions = SignupAction | LoginAction | LogoutAction

export const signupUser = (data: AuthUserType) => {
    // api call
}

export const loginUser = (data: LoginDataType) => {
    return async (dispatch: Dispatch) => {

        return api.post<APIResponse<AuthUserType>>('user/login/', data).then(response => {

            if (response.status === 200 && response.data.data) {
                dispatch<LoginAction>({
                    type: AuthUserActionList.LOGIN_USER,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Successfully logged in')
            } else {
                throw { response }
            }
        }).catch(error => {

            return Promise.reject(errorHandler(error, 'Unable to login'))
        })
    }
}

export const logoutUser = () => {
    return (dispatch: Dispatch) => {
        dispatch<LogoutAction>({
            type: AuthUserActionList.LOGOUT_USER,
            data: null
        })
    }
}