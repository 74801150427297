import { useState } from "react"
import { Button, Card } from "react-bootstrap"
import { ToastContainer, toast } from "react-toastify"
import CollapseCard from "../components/custom/CollapseCard"
import PageContainer from "../components/custom/PageContainer"
import Modal from 'react-bootstrap/Modal'
import { useDispatch, useSelector } from "react-redux"
import { DropDownType, addDropDown, deleteDropDown, updateDropDown } from "../redux/actions/dropDownAction"
import { StoreState } from "../redux/reducers"
import { AppDispatch } from "../redux/store"
import Main from "../components/custom/Main"
import "../styles/Table.css"

export const DropDownMaster = () => {

    const dispatch = useDispatch<AppDispatch>();
    const dropdownList = useSelector<StoreState, DropDownType[]>(state => state.dropDown)

    const [category, setCategory] = useState<string>('')
    const [categoryError, setCategoryError] = useState<string>('')
    const [title, setTitle] = useState<string>('')
    const [titleError, setTitleError] = useState<string>('')
    const [id, setId] = useState<number>(0)

    const [apiErrorMessage, setApiErrorMessage] = useState('');
    const [editFlag, setEditFlag] = useState(false);

    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

    const clearHandler = () => {
        setCategoryError('')
        setTitle('')
        setTitleError('')
        setEditFlag(false);
    }

   
    const handleAdd = () => {
        const data = {
            category: category,
            title: title?.toUpperCase()
        }

        

        if (category !== '' && title !== '') {
            dispatch(addDropDown(data)).then(text => {
                toast.success(text)
                setCategoryError('')
                setTitleError('')
                clearHandler();
            }).catch(text => {
                toast.error(text)
            })
        } else toast.error('Category and Title required')

    }


    const editHandler = (id: number, category: string, title: string) => {
        setEditFlag(true);
        setId(id)
        setCategory(category)
        setTitle(title)
    }

    const handleUpdate = () => {

        const data = { id: id, category: category, title: title?.toUpperCase() }

        if (category !== '' && title !== '') {
            dispatch(updateDropDown(data, id)).then(text => {
                toast.success(text)
                setCategoryError('')
                setTitleError('')
                clearHandler();
            }).catch(text => {
                toast.error(text)
            })
        } else toast.error('Category and Title required')

    }

    const deleteHandle = (id: number) => {
        setId(id);
        openDeleteConfirmation();
    }

    const openDeleteConfirmation = () => {
        setShowDeleteConfirmation(true);
    }

    const closeDeleteConfirmation = () => {
        setShowDeleteConfirmation(false);
    }

    const confirmDeleteHandler = () => {
        if (id !== undefined) {
            dispatch(deleteDropDown(id)).then(text => {
                toast.success(text)
                closeDeleteConfirmation();
            }).catch(text => {
                toast.error(text)
            })
        }
    }

    return (
        <>
           <Main>
            <PageContainer title="DropDown Master">
                <Card title={editFlag ? "Edit Dropdowm" : "Add DropDown"}>
                    <div className="container">
                        <div className="row">
                            <div className="col-6 mb-3">
                                {apiErrorMessage && <div className="text-danger">{apiErrorMessage}</div>}
                                <label htmlFor="" className="mt-3 m-2">Category<span className='text-danger'>*</span></label>
                                <select name="" id="" className="form-control" value={category} onChange={(e) => {
                                    setCategory(e.target.value)
                                    setCategoryError('')
                                }}>
                                    <option value="" >Select</option>
                                    <option value="Blood Group">Blood Group</option>
                                    <option value="Community">Community</option>
                                    <option value="Caste">Caste</option>
                                    <option value="Religion">Religion</option>
                                    <option value="Course Type">Course Type</option>
                                    <option value="Qualification">Qualification</option>
                                    <option value="Role">Role</option>
                                </select>
                                {categoryError && <div className="text-danger">{categoryError}</div>}
                            </div>
                        </div>
                    </div>
                </Card>
                <CollapseCard title="DropDown List">
                    <div className="container">
                        <div className="row mb-2">
                            <div className="col-6">
                                <div className="hstack">
                                    {apiErrorMessage && <div className="text-danger">{apiErrorMessage}</div>}
                                    <label>Title<span className='text-danger mb-2'>*</span></label>
                                    <input type="text" className="form-control text-uppercase" name="title" placeholder="Enter Title..." value={title} onChange={(e) => {
                                        setTitle(e.target.value)
                                        setTitleError('')
                                    }} />
                                </div>
                            </div>

                            <div className="col-4 mt-2 mb-2">
                                <label className=""></label><br />
                                {editFlag ? <span className="btn btn-sm  " onClick={handleUpdate} style={{ marginRight: '10px', backgroundColor: "#10296C", color: "#fff" }} >Update</span> :
                                    <span className="btn btn-sm" onClick={handleAdd} style={{ marginRight: '10px', backgroundColor: "#10296C", color: "#fff" }} >Add</span>
                                }
                                <span className="btn btn-sm btn-secondary " onClick={clearHandler} >Clear</span>

                            </div>

                        </div>

                        <table className="table table-striped">
                        <thead style={{ backgroundColor: "#10296C", color: "#fff" }}>
                                <tr>
                                    <th>S.No.</th>
                                    <th>Title</th>
                                    <th>option</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    dropdownList.filter((dp) => dp.category === category).map((dd, i) => (
                                        <tr key={i}>
                                            <td>{i + 1}</td>
                                            <td>{dd.title}</td>
                                            <td>
                                                <button className="btn btn-sm" style={{ marginRight: '10px', backgroundColor: "#10296C", color: "#fff" }} onClick={(e) => editHandler(dd.id ? dd.id : 0, dd.category, dd.title)}>Edit</button>
                                                <button className="btn btn-sm btn-danger" onClick={(e) => deleteHandle(dd.id ? dd.id : 0)}>Delete</button>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>

                </CollapseCard>
                <Modal show={showDeleteConfirmation} onHide={closeDeleteConfirmation}>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirm Delete Form</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Are you sure you want to delete this item?
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={closeDeleteConfirmation}>
                            Cancel
                        </Button>
                        <Button variant="danger" onClick={confirmDeleteHandler}>
                            Delete
                        </Button>
                    </Modal.Footer>
                </Modal>
                <ToastContainer position="top-right" />
            </PageContainer>
        </Main>
        </>
    )
}

interface DropDownMaster {
    id: number,
    category: string,
    title: string
}