import React, { FC } from "react";

interface Props {
    title:string,
    footer?:string
    children: string | JSX.Element | JSX.Element[]
}
const PageContainer:FC<Props> = (props) =>{
    const {title,children} = props;
    return(
        <div className="content-wrapper" style={{backgroundColor: "#F0F8FF", marginTop: "4%"}}>
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>{title}</h1>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          {children}
        </section>
      </div>
    )
}
export default PageContainer;