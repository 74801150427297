import React, { useEffect, useState } from "react";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { apiUrl } from '../config';
import { CourseType, StudentType } from "./Interface";
import Main from "../components/custom/Main";
import PageContainer from "../components/custom/PageContainer";
import CollapseCard from "../components/custom/CollapseCard";
import * as Feather from 'react-feather';
import { Popover, OverlayTrigger, Button, Modal, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../redux/store";
import { StoreState } from "../redux/reducers";
import { AuthUserType } from "../redux/actions/authUserActions";
import { fetchUser, UserType } from "../redux/actions/userActions";
import jsPDF from 'jspdf';
import autoTable from "jspdf-autotable";
import Papa from "papaparse";
import { CSVLink } from 'react-csv';
import { BranchType } from "../redux/actions/branchActions";

const MainStudentView = () => {
    const dispatch = useDispatch<AppDispatch>()
    const branchList = useSelector<StoreState, BranchType[]>(state => state.branch)
    const authUser = useSelector<StoreState, AuthUserType>((state: any) => state.authUser)
    const [courseList, setCourseList] = useState<CourseType[]>([]);
    const [studentList, setStudentList] = useState<StudentType[]>([]);
    const [id, setId] = useState<number>(0);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [filteredStudents, setFilteredStudents] = useState<StudentType[]>([]);
    const [branchFilter, setBranchFilter] = useState<string>('');
    const [courseFilter, setCourseFilter] = useState<string>('');
    const [batchFilter, setBatchFilter] = useState<string>('');

    const navigate = useNavigate();

    useEffect(() => {
        axios.get<{ data: CourseType[] }>(`${apiUrl}/course/`)
            .then((response) => {
                setCourseList(response.data.data);
            })
            .catch((error) => console.log(error));
    }, []);

    useEffect(() => {
        dispatch(fetchUser())
        fetchData();
    }, []);

    const fetchData = () => {
        axios.get<{ data: StudentType[] }>(`${apiUrl}/student/`)
            .then((response) => {
                console.log(response);
                setStudentList(response.data.data);
                setFilteredStudents(response.data.data);
            })
            .catch((error) => console.log(error));
    }

    useEffect(() => {
        handleFilterChange();
    }, [branchFilter, courseFilter, batchFilter]);
    
    const handleFilterChange = () => {
        let filtered = studentList;
    
        if (branchFilter && branchFilter !== "all") {
            filtered = filtered.filter(stu => stu.branchId === parseInt(branchFilter));
        }
    
        if (courseFilter && courseFilter !== "all") {
            filtered = filtered.filter(stu => stu.courseId === parseInt(courseFilter));
        }
    
        if (batchFilter && batchFilter !== "all") {
            filtered = filtered.filter(stu => stu.batch === batchFilter);
        }
    
        if (!branchFilter && !courseFilter && !batchFilter) {
            filtered = studentList;
        }
    
        setFilteredStudents(filtered);
    };
    



    const handleAddNewStudent = () => {
        navigate('/student-info');
    }

    const handleViewDetails = (id: number) => {
        navigate(`/student-info/${id}`);
    }

    const handleAttachment = (id: number) => {
        navigate(`/student-attachment/${id}`);
    }

    const openDeleteConfirmation = () => {
        setShowDeleteConfirmation(true);
    }

    const closeDeleteConfirmation = () => {
        setShowDeleteConfirmation(false);
    }

    const deleteHandler = (id: number) => {
        setId(id);
        openDeleteConfirmation()
    }

    const confirmDeleteHandler = () => {
        axios.delete(`${apiUrl}/student/?id=${id}`)
            .then((response) => {
                toast.success(response.data.message, { position: 'top-right' });
                fetchData();
                closeDeleteConfirmation();
            })
            .catch((error) => {
                toast.error(error.response.data.message, { position: 'top-right' });
            });
    }

    // PDF Download
    const downloadPDF = () => {
        const doc = new jsPDF();
        const title = "Student Report";

        doc.setFontSize(18);

        const pageWidth = doc.internal.pageSize.getWidth();
        const titleWidth = doc.getTextWidth(title);
        const titleX = (pageWidth - titleWidth) / 2;

        doc.text(title, titleX, 10);
        autoTable(doc, {
            head: [['S.No', 'Name', 'Register No', 'Branch', 'Course', 'Batch']],
            body: filteredStudents.map((stu, i) => [
                i + 1,
                stu.name,
                stu.registerNumber,
                stu.branchName || "",
                stu.courseName || "",
                stu.batch
            ])
        });
        doc.save('student.pdf');
    };

    // CSV Download
    const downloadCSV = () => {
        const csvData = filteredStudents.map((stu, i) => ({
            'S.No': i + 1,
            'Name': stu.name,
            'Register No': stu.registerNumber,
            'Branch': stu.branchName,
            'Course': stu.courseName,
            'Batch': stu.batch
        }));

        const csv = Papa.unparse(csvData);
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'student.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    let currentDate = new Date();
    let year = currentDate.getFullYear();

    let min = 0;
    let max = 10;


    let last10Years = [];
    for (let i = min; i <= max; i++) {
        let dropYear = year - i;
        last10Years.push(dropYear);
    }

    // Popovers for each button
    const viewPopover = (
        <Popover id="popover-view">
            <Popover.Body>View student details</Popover.Body>
        </Popover>
    );

    const attachmentPopover = (
        <Popover id="popover-attachment">
            <Popover.Body>Attach documents</Popover.Body>
        </Popover>
    );

    const deletePopover = (
        <Popover id="popover-delete">
            <Popover.Body>Delete student</Popover.Body>
        </Popover>
    );

    return (
        <Main>
            <PageContainer title="Student View">
                <div className="m-4 d-flex justify-content-end">
                    <button className="btn btn-success" onClick={handleAddNewStudent}>Add New Student</button>
                </div>
                <CollapseCard title="Student List">
                    <div className="row mb-4">
                        <div className='col-sm-6 col-md-4 col-lg-3'>
                            <label className="mt-3 mb-3">Branch : </label>
                            <select className="form-control form-select" value={branchFilter} onChange={(e) => setBranchFilter(e.target.value)}>
                                <option value="all">Select Branch</option>
                                {branchList.map((branch) => (
                                    <option key={branch.id} value={branch.id}>{branch.name}</option>
                                ))}
                            </select>
                        </div>
                        <div className='col-sm-6 col-md-4 col-lg-3'>
                            <label className="mt-3 mb-3">Course : </label>
                            <select className="form-control form-select" value={courseFilter} onChange={(e) => setCourseFilter(e.target.value)}>
                                <option value="all">Select Course</option>
                                {courseList.map((course) => (
                                    <option key={course.id} value={course.id}>{course.name}</option>
                                ))}
                            </select>
                        </div>
                        <div className='col-sm-6 col-md-4 col-lg-3'>
                            <label className="mt-3 mb-3">Batch :</label>
                            <select name="" id="" className='form-control' value={batchFilter} onChange={(e) => {
                                setBatchFilter(e.target.value)
                            }}>
                                <option value="all">Select Batch</option>
                                {
                                    last10Years.map((ly => {
                                        return <option value={ly}>{ly}</option>
                                    }))
                                }
                            </select>
                        </div>
                    </div>

                    <div className="container-fluid" style={{ width: "100%" }}>
                        <table className="table table-striped">
                            <thead style={{ backgroundColor: "#10296C", color: "#fff" }}>
                                <tr>
                                    <th>S.No</th>
                                    <th>Photo</th>
                                    <th>Name</th>
                                    <th>Register No</th>
                                    <th>Branch</th>
                                    <th>Course</th>
                                    <th>Batch</th>
                                    <th>Option</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredStudents.map((stu, index) => (
                                    <tr key={stu.id}>
                                        <td>{index + 1}</td>
                                        <td>
                                            <img
                                                src={apiUrl + "/" + stu.photoUrl}
                                                alt={stu.name}
                                                className="student-thumbnail"
                                                style={{ objectFit: "contain", height: "70px", width: "70px" }}
                                            />
                                        </td>
                                        <td>{stu.name}</td>
                                        <td>{stu.registerNumber}</td>
                                        <td>{stu.branchName}</td>
                                        <td>{stu.courseName}</td>
                                        <td>{stu.batch}</td>
                                        <td>
                                            <OverlayTrigger
                                                trigger={['hover', 'focus']}
                                                placement="top"
                                                overlay={viewPopover}
                                            >
                                                <Button
                                                    className="btn btn-sm text-white"
                                                    style={{ backgroundColor: "#10296C", padding: "5px", borderRadius: "5px", marginRight: "5px" }}
                                                    onClick={() => handleViewDetails(stu.id)}
                                                >
                                                    <Feather.Eye style={{ width: "20px" }} />
                                                </Button>
                                            </OverlayTrigger>

                                            <OverlayTrigger
                                                trigger={['hover', 'focus']}
                                                placement="top"
                                                overlay={attachmentPopover}
                                            >
                                                <Button
                                                    className="btn btn-sm text-white"
                                                    style={{ backgroundColor: "#10296C", padding: "5px", borderRadius: "5px", marginRight: "5px" }}
                                                    onClick={() => handleAttachment(stu.id)}
                                                >
                                                    <Feather.Paperclip style={{ width: "20px" }} />
                                                </Button>
                                            </OverlayTrigger>

                                            <OverlayTrigger
                                                trigger={['hover', 'focus']}
                                                placement="top"
                                                overlay={deletePopover}
                                            >
                                                <Button
                                                    className="btn btn-sm btn-danger"
                                                    onClick={() => deleteHandler(stu.id)}
                                                >
                                                    <Feather.Trash2 style={{ width: "20px" }} />
                                                </Button>
                                            </OverlayTrigger>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        {filteredStudents.length > 0 && (
                            <div className="col-12 m-3 d-flex justify-content-end">
                              <button
                                  className="btn btn-success mx-2"
                                  onClick={downloadPDF}
                                  style={{ fontSize: "14px" }}
                              >
                                <Feather.Download style={{ width: "20px" }} /> PDF                                                                                   
                              </button>
                              <button
                                  className="btn btn-success mx-2"
                                  onClick={downloadCSV}
                                  style={{ fontSize: "14px" }}
                              >
                                  <Feather.Download style={{ width: "20px" }} /> CSV
                              </button>
                          </div>
                        )}

                    </div>
                </CollapseCard>
                <Modal show={showDeleteConfirmation} onHide={closeDeleteConfirmation}>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirm Deletion</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Are you sure you want to delete this item?
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={closeDeleteConfirmation}>
                            Cancel
                        </Button>
                        <Button variant="danger" onClick={confirmDeleteHandler}>
                            Delete
                        </Button>
                    </Modal.Footer>
                </Modal>
                <ToastContainer />
            </PageContainer>
        </Main>
    );
}

export default MainStudentView;
