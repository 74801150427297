import React, { useEffect, useState } from "react";
import axios from "axios";
import { Container, Row, Col, Card } from "react-bootstrap";
import Main from "../components/custom/Main";
import PageContainer from "../components/custom/PageContainer";
import { apiUrl } from "../config";
import "../styles/Dashboard.css";
import CollapseCard from "../components/custom/CollapseCard";

type StudentCount = {
  branchName: string;
  courseName: string;
  batch: string;
  studentCount: number;
};

const Dashboard: React.FC = () => {
  const [students, setStudents] = useState<StudentCount[]>([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    axios
      .get<{ data: StudentCount[] }>(`${apiUrl}/student/studentCountByCourse/`)
      .then((response) => {
        setStudents(response.data.data);
      })
      .catch((error) => console.log(error));
  };

  return (
    <Main>
      <PageContainer title="Dashboard">
        <Container fluid style={{height: "74vh"}}>
          <CollapseCard title="Student Counts by Course">
            <div className="table-responsive">
              <table className="table table-striped table-bordered text-center">
                <thead style={{ backgroundColor: "#10296C", color: "#fff" }}>
                  <tr>
                    <th>S.No</th>
                    <th>Branch</th>
                    <th>Course</th>
                    <th>Batch</th>
                    <th>Student Count</th>
                  </tr>
                </thead>
                <tbody>
                  {students.map((student, index) => (
                    <tr key={index + 1} className="hover-row">
                      <td>{index + 1}</td>
                      <td>{student.branchName}</td>
                      <td>{student.courseName}</td>
                      <td>{student.batch}</td>
                      <td style={{
                        fontWeight: 'bold',
                        color: student.studentCount === 0 ? 'red' : 'green'
                      }}>
                        {student.studentCount}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </CollapseCard>
        </Container>
      </PageContainer>
    </Main>
  );
};

export default Dashboard;
