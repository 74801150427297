import React, { useState, useEffect } from 'react'
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Main from '../components/custom/Main'
import PageContainer from '../components/custom/PageContainer'
import Card from '../components/custom/Card'
import CollapseCard from '../components/custom/CollapseCard';
import { apiUrl } from '../config';
import { CourseType } from './Interface';
import "../styles/Table.css"
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../redux/store';
import { StoreState } from '../redux/reducers';
import { DropDownType, fetchDropDown } from '../redux/actions/dropDownAction';
import { BranchType, fetchBranch } from '../redux/actions/branchActions';

const Course = () => {
    const dispatch = useDispatch<AppDispatch>()
    const dropDownList = useSelector<StoreState, DropDownType[]>(state => state.dropDown)
    const branchList = useSelector<StoreState, BranchType[]>(state => state.branch)
    const [courseList, setCourseList] = useState<CourseType[]>([]);
    const [editFlag, setEditFlag] = useState(false);
    const [id, setId] = useState<number>(0);
    const [branchId, setBranchId] = useState<number>(0);
    const [name, setName] = useState<string>("");
    const [code, setCode] = useState<string>("");
    const [type, setType] = useState<string>("");
    const [noOfYears, setNoOfYears] = useState<string>("");
    const [noOfSemester, setNoOfSemester] = useState<string>("");

    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

    const [branchIdError, setBranchIdError] = useState<string>("");
    const [nameError, setNameError] = useState<string>("");
    const [codeError, setCodeError] = useState<string>("");
    const [typeError, setTypeError] = useState<string>("");
    const [noOfYearsError, setNoOfYearsError] = useState<string>("");
    const [noOfSemesterError, setNoOfSemesterError] = useState<string>("");

    useEffect(() => {
        fetchData();
        dispatch(fetchDropDown())
        dispatch(fetchBranch())
    }, [])

    const fetchData = () => {
        axios.get<{ data: CourseType[] }>(`${apiUrl}/course/`)
            .then((response) => {
                setCourseList(response.data.data);
            })
            .catch((error) => console.log(error));
    }

    const clearHandler = () => {
        setBranchId(0);
        setName("");
        setCode("");
        setType("");
        setNoOfYears("");
        setNoOfSemester("");

        setBranchIdError("");
        setNameError("");
        setCodeError("");
        setTypeError("");
        setNoOfYearsError("");
        setNoOfSemesterError("");

        setEditFlag(false)
    }

    const addHandler = () => {
        let error = false
        if (branchId === 0) {
            setBranchIdError('Branch Id is required');
            error = true
        } else if (branchId.toString().length > 11) {
            setBranchIdError("Branch id must be within 11 characters");
            error = true
        }

        if (name === "") {
            setNameError('Name is required');
            error = true
        } else if (name.length > 100) {
            setNameError("Name must be within 100 characters");
            error = true
        }

        if (code === "") {
            setCodeError('Code is required');
            error = true
        } else if (code.length > 15) {
            setCodeError("Code must be within 15 characters")
            error = true
        }

        if (type === "") {
            setTypeError('Type is required');
            error = true
        } else if (type.length > 20) {
            setTypeError("Type must be within 20 characters")
            error = true
        }

        if (noOfYears === "") {
            setNoOfYearsError('No of years is required');
            error = true
        } else if (noOfYears.length > 5) {
            setNoOfYearsError("No of years must be within 5 characters")
        }

        if (noOfSemester === "") {
            setNoOfSemesterError('No of semester is required');
            error = true
        } else if (noOfSemester.length > 11) {
            setNoOfSemesterError("No of semester must be within 11 characters")
        }

        const data = { branchId, name: name.toUpperCase(), code, type, noOfYears, noOfSemester };

        if (!error) {
            axios.post(`${apiUrl}/course/`, data)
                .then((response) => {
                    if (response.data.error) {
                        toast.error(response.data.message, { position: 'top-right' });
                    } else {
                        toast.success(response.data.message, { position: 'top-right' });
                        fetchData();
                        clearHandler();
                    }
                })
                .catch((error) => toast.error(error.response.data.message));
        }
    }

    const editHandler = (id: number, branchId: number, name: string, code: string, type: string, noOfYears: string, noOfSemester: string) => {
        setEditFlag(true);
        setId(id);
        setBranchId(branchId);
        setName(name);
        setCode(code);
        setType(type);
        setNoOfYears(noOfYears);
        setNoOfSemester(noOfSemester)
    }

    const updateHandler = () => {
        let error = false
        if (branchId === 0) {
            setBranchIdError('Branch Id is required');
            error = true
        } else if (branchId.toString().length > 11) {
            setBranchIdError("Branch id must be within 11 characters");
            error = true
        }

        if (name === "") {
            setNameError('Name is required');
            error = true
        } else if (name.length > 100) {
            setNameError("Name must be within 100 characters");
            error = true
        }

        if (code === "") {
            setCodeError('Code is required');
            error = true
        } else if (code.length > 15) {
            setCodeError("Code must be within 15 characters")
            error = true
        }

        if (type === "") {
            setTypeError('Type is required');
            error = true
        } else if (type.length > 20) {
            setTypeError("Type must be within 20 characters")
            error = true
        }

        if (noOfYears === "") {
            setNoOfYearsError('No of years is required');
            error = true
        } else if (noOfYears.length > 5) {
            setNoOfYearsError("No of years must be within 5 characters")
        }

        if (noOfSemester === "") {
            setNoOfSemesterError('No of semester is required');
            error = true
        } else if (noOfSemester.length > 11) {
            setNoOfSemesterError("No of semester must be within 11 characters")
        }

        const data = { id, branchId, name: name.toUpperCase(), code, type, noOfYears, noOfSemester }

        if (!error) {
            axios.put(`${apiUrl}/course/?id=${id}`, data)
                .then((response) => {
                    if (response.data.error) {
                        toast.error(response.data.message, { position: 'top-right' });
                    } else {
                        toast.success(response.data.message, { position: 'top-right' });
                        setEditFlag(false);
                        fetchData();
                        clearHandler();
                    }
                })
                .catch((error) => toast.error(error.response.data.message));
        }
    }

    const openDeleteConfirmation = () => {
        setShowDeleteConfirmation(true);
    }

    const closeDeleteConfirmation = () => {
        setShowDeleteConfirmation(false);
    }

    const deleteHandler = (id: number) => {
        setId(id);
        openDeleteConfirmation()
    }

    const confirmDeleteHandler = () => {
        axios.delete(`${apiUrl}/course/?id=${id}`)
            .then((response) => {
                toast.success(response.data.message, { position: 'top-right' });
                fetchData();
                closeDeleteConfirmation();
            })
            .catch((error) => {
                toast.error(error.response.data.message, { position: 'top-right' });
            });
    }

    return (
        <Main>
            <PageContainer title="Course">
                <Card title={editFlag ? "Edit Course" : "Add Course"}>
                    <div className="row">
                        <div className='col-sm-6 col-md-4 col-lg-3'>
                            <label className="mt-3 mb-3">Branch <span className="text-danger">*</span> : </label>
                            <select className="form-control form-select" aria-label="Default select example" value={branchId} onChange={(e) => setBranchId(Number(e.target.value))}>
                                <option value={0}>Select Branch</option>
                                {branchList.map((branch) => (
                                    <option key={branch.id} value={branch.id}>{branch.name}</option>
                                ))}
                            </select>
                            {branchIdError && <div className="text-danger">{branchIdError}</div>}
                        </div>
                        <div className='col-sm-6 col-md-4 col-lg-3'>
                            <label className="mt-3 mb-3">Name <span className="text-danger">*</span> : </label>
                            <input className="form-control text-Uppercase" placeholder="Enter name..." type="text" name="name" onChange={(e) => {
                                setName(e.target.value);
                            }} value={name} />
                            {nameError && <div className="text-danger">{nameError}</div>}
                        </div>
                        <div className='col-sm-6 col-md-4 col-lg-3'>
                            <label className="mt-3 mb-3">Code <span className="text-danger">*</span> : </label>
                            <input className="form-control" placeholder="Enter code..." type="text" name="name" onChange={(e) => {
                                setCode(e.target.value);
                            }} value={code} />
                            {codeError && <div className="text-danger">{codeError}</div>}
                        </div>
                        <div className='col-sm-6 col-md-4 col-lg-3'>
                            <label className="mt-3 mb-3">Course Type <span className="text-danger">*</span> : </label>
                            <select
                                className="form-control"
                                value={type}
                                onChange={(e) => {
                                    setType(e.target.value);
                                    setTypeError('');
                                }}

                            >
                                <option value="" disabled>Select Type</option>
                                {
                                    dropDownList.filter(dl => dl.category === 'Course Type').map((dd) => {
                                        return <option value={dd.title}>{dd.title.toUpperCase()}</option>
                                    })
                                }


                            </select>
                            {typeError && <div className="text-danger">{typeError}</div>}
                        </div>
                        <div className='col-sm-6 col-md-4 col-lg-3'>
                            <label className="mt-2 mb-2">No Of Years <span className='text-danger'>*</span> :</label>
                            <select name="" id="" className='form-control' value={noOfYears} onChange={(e) => {
                                setNoOfYears(e.target.value)
                                setNoOfYearsError('');
                            }}>
                                <option value="">Select No of years</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                            </select>
                            {noOfYearsError && <div className="text-danger">{noOfYearsError}</div>}
                        </div>
                        <div className='col-sm-6 col-md-4 col-lg-3'>

                            <label className="mt-2 mb-2">No Of Semester <span className='text-danger'>*</span> :</label>
                            <select
                                className="form-control"
                                name="currentSemester"
                                onChange={(e) => {
                                    setNoOfSemester(e.target.value);
                                    setNoOfSemesterError('');
                                }}
                                value={noOfSemester}
                                disabled={type === 'edit' && !editFlag}
                            >
                                <option value="">Select Semester</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                            </select>
                            {noOfSemesterError && <div className="text-danger">{noOfSemesterError}</div>}
                        </div>
                    </div>
                    <div className="m-4 d-flex justify-content-end">
                        <button className="btn btn-sm btn-secondary" onClick={clearHandler} style={{ marginRight: '10px' }}>Clear</button>
                        {editFlag ? <button className="btn btn-sm" onClick={updateHandler} style={{ backgroundColor: "#10296C", color: "#fff" }}>Update</button> :
                            <button className="btn btn-sm" onClick={addHandler} style={{ backgroundColor: "#10296C", color: "#fff" }}>Add</button>}
                    </div>
                </Card>
                <CollapseCard title="Course List">
                    <div className="container-fluid" style={{ width: "100%", textTransform: "capitalize" }}>
                        <table className="table table-striped">
                            <thead style={{ backgroundColor: "#10296C", color: "#fff" }}>
                                <tr>
                                    <th>S.No</th>
                                    <th>Branch</th>
                                    <th>Name</th>
                                    <th>Code</th>
                                    <th>Type</th>
                                    <th>No Of Years</th>
                                    <th>No Of Semester</th>
                                    <th>Options</th>
                                </tr>
                            </thead>
                            <tbody>
                                {courseList.map((course, index) => (
                                    <tr key={course.id}>
                                        <td>{index + 1}</td>
                                        <td>{course.branchName}</td>
                                        <td>{course.name}</td>
                                        <td>{course.code}</td>
                                        <td>{course.type}</td>
                                        <td>{course.noOfYears}</td>
                                        <td>{course.noOfSemester}</td>
                                        <td>
                                            <button className="btn btn-sm btn-primary" style={{ marginRight: '10px', backgroundColor: '#10296C' }} onClick={() => editHandler(course.id, course.branchId, course.name, course.code, course.type, course.noOfYears, course.noOfSemester)}>Edit</button>
                                            <button className="btn btn-sm btn-danger" onClick={() => deleteHandler(course.id)}>Delete</button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </CollapseCard>
                {/* Deletion Confirmation Modal */}
                <Modal show={showDeleteConfirmation} onHide={closeDeleteConfirmation}>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirm Deletion</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Are you sure you want to delete this item?
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={closeDeleteConfirmation}>
                            Cancel
                        </Button>
                        <Button variant="danger" onClick={confirmDeleteHandler}>
                            Delete
                        </Button>
                    </Modal.Footer>
                </Modal>
                {/* Add the ToastContainer for displaying toasts */}
                <ToastContainer position="top-right" />
            </PageContainer>
        </Main>
    )
}

export default Course

