import { ChangeEventHandler, useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import FormInput from '../components/custom/FormInput'
import VerticalForm from '../components/custom/components/VerticalForm'
import AuthLayout from '../components/custom/pages/AuthLayout'
import { ToastTypes, useToast } from '../components/toast/ToastProvider'
import { LoginDataType, loginUser } from '../redux/actions/authUserActions'
import { AppDispatch } from '../redux/store'

const Login = () => {
    const toast = useToast()
    const dispatch = useDispatch<AppDispatch>()

    const [loading, setLoading] = useState<boolean>(false)
    const [mounted, setMounted] = useState<boolean>(false)

    const [username, setUsername] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [passwordError, setPasswordError] = useState<string>('')
    const [usernameError, setUsernameError] = useState<string>('')

    const handleUsername: ChangeEventHandler<HTMLInputElement> = (event) => {
        setUsernameError('')
        setUsername(event.target.value)
    }

    const handlePassword: ChangeEventHandler<HTMLInputElement> = (event) => {
        setPasswordError('')
        setPassword(event.target.value)
    }

    const handleLogin = (event: any) => {
        event.preventDefault()
        const data: LoginDataType = {
            userId: username,
            password: password
        }

        

        let error = false

        if (data.userId === '') {
            setUsernameError('Username required')
            error = true
        }

        if (data.password === '') {
            setPasswordError('Password required')
            error = true
        }

        if (!error) {
            setLoading(true)
            dispatch(loginUser(data)).then(text => {
                toast(text)
            }).catch(text => {
                toast(text, ToastTypes.ERROR)
            }).finally(() => {
                if (mounted) {
                    setLoading(false)
                }
            })
        }
    }

    useEffect(() => {
        setMounted(true)
        return () => setMounted(false)
    }, [])

    return <AuthLayout
        helpText={'User Login'}
    >
        <VerticalForm onSubmit={handleLogin}>
            <FormInput
                label={'Username'}
                type='text'
                name='username'
                value={username}
                placeholder='Enter your Username'
                onChange={handleUsername}
                errorText={usernameError}
                containerClass='mb-3'
            />
            <FormInput
                label={'Password'}
                type='password'
                name='password'
                value={password}
                placeholder='Enter your password'
                onChange={handlePassword}
                errorText={passwordError}
                containerClass='mb-3'
            />

            <div className='text-right d-grid'>
                {loading
                    ? <Button type='button' disabled className='btn-sm p-2' style={{backgroundColor: "#10296C", color: "#fff"}}>
                        Log In
                    </Button>
                    : <Button type='submit' className='btn-sm p-2' style={{backgroundColor: "#10296C", color: "#fff"}}>
                        Log In
                    </Button>}
            </div>
            {/* <div className="mt-2">
                <Link to="#" className="text-primary-50">
                    Forgot your password?
                </Link>
            </div> */}

        </VerticalForm>
    </AuthLayout>
}

export default Login