import React, { useState, useEffect } from "react";
import Select from "react-select";
import axios from 'axios';
import { apiUrl } from '../config'; // assuming you have apiUrl defined in config
import PageContainer from "../components/custom/PageContainer";
import Main from "../components/custom/Main";
import { MENU_ITEMS } from "../components/custom/SideNavBar";
import Card from "../components/custom/Card";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../redux/store";
import { StoreState } from "../redux/reducers";
import { DropDownType, fetchDropDown } from "../redux/actions/dropDownAction";
import { BranchType, fetchBranch } from "../redux/actions/branchActions";
import FormInput from "../components/custom/FormInput";

// Define RoleType
type RoleType = {
    id: number;
    role: string;
    branchId: number;
    pageTitle: string;
    pageUrl: string;
    add: boolean;
    view: boolean;
    edit: boolean;
    delete: boolean;
    created_by: string;
    createdAt: string;
};

// Initial dynamic modules from the MENU_ITEMS
const dynamicModules = MENU_ITEMS.flatMap(menuItem => {
    if (menuItem.children && menuItem.children.length > 0) {
        return menuItem.children.map(child => ({
            pageParentTitle: menuItem.label || "",
            pageTitle: child.label || "",
            pageUrl: child.url || "",
            add: false,
            edit: false,
            view: false,
            delete: false,
        }));
    } else if (menuItem.label !== "Logout" && menuItem.label !== "Profile") {
        return [{
            pageTitle: menuItem.label || "",
            pageUrl: menuItem.url || "",
            add: false,
            edit: false,
            view: false,
            delete: false,
        }];
    } else {
        return [];
    }
});

const RoleManagement = () => {
    const dispatch = useDispatch<AppDispatch>();
    const dropDownList = useSelector<StoreState, DropDownType[]>(state => state.dropDown)
    const branchList = useSelector<StoreState, BranchType[]>(state => state.branch);

    const [menuContent, setMenuContent] = useState(dynamicModules);
    const [roles, setRoles] = useState<RoleType[]>([]);
    const [selectedRole, setSelectedRole] = useState<{ value: string, label: string }[]>([]);
    const [roleName, setRoleName] = useState<string>('');
    const [branchId, setBranchId] = useState<string>('');
    const [branchIdError, setBranchIdError] = useState<string>('');
    const [roleError, setRoleError] = useState<string>('');
    const [id, setId] = useState<number | null>(null);
    const [formType, setFormType] = useState('ADD');

    useEffect(() => {
        dispatch(fetchDropDown())
        dispatch(fetchBranch())
    }, []);

    useEffect(() => {
        if (selectedRole.length > 0 && branchId) {
            getRole();  
        }
    }, [selectedRole, branchId]); 


    const getRole = () => {
        const selectedRoles = selectedRole.map(role => role.value);
        axios.get(`${apiUrl}/role_permission/?role=${selectedRoles}&branchId=${branchId}`)
            .then((response) => {
                const rolePermissions = response.data.data;
                if (rolePermissions && rolePermissions.length > 0) {
                    setMenuContent(prevMenuContent =>
                        prevMenuContent.map(module => {
                            const roleData = rolePermissions.find((data: RoleType) => data.pageTitle === module.pageTitle);
                            return roleData
                                ? {
                                    ...module,
                                    add: roleData.add === 'true', 
                                    edit: roleData.edit === 'true', 
                                    view: roleData.view === 'true', 
                                    delete: roleData.delete === 'true', 
                                }
                                : module;
                        })
                    );
                    setFormType('EDIT');
                    // setRoleName(rolePermissions[0].role); 
                    // setBranchId(rolePermissions[0].branchId);
                } else {
                    setMenuContent(dynamicModules);
                    setFormType('ADD');
                    // setRoleName("");
                    // setBranchId("");
                }
            })
            .catch((error) => {
                console.error('Error fetching role permissions', error);
            });
    };
    
    const handleRoleChange = (selectedOption: any) => {
        console.log(selectedOption);
        setSelectedRole(selectedOption || []);
    };

    const handlePermissionChange = (pageTitle: string, action: string, checked: boolean) => {
        console.log(`Changing ${action} for ${pageTitle} to ${checked}`); 
        setMenuContent(prevMenuContent =>
            prevMenuContent.map(module =>
                module.pageTitle === pageTitle
                    ? { ...module, [action]: checked }
                    : module
            )
        );
    };

    const handleSave = () => {
        let error = false;

        const role = selectedRole.map(role => role.value)

        const roleProperties = menuContent.map(module => ({
            pageTitle: module.pageTitle,
            pageUrl: module.pageUrl,
            add: module.add,
            edit: module.edit,
            view: module.view,
            delete: module.delete,
            branchId: branchId,
        }));

        const roleData = {
            id: id,
            role: role,
            branchId: branchId,
            roleProperties: roleProperties,
        };

        if (!error) {
            if (formType === 'ADD') {
                axios.post(`${apiUrl}/role_permission/`, roleData)
                    .then(() => {
                        alert('Role added successfully');
                        fetchDropDown();
                        resetStates();
                    })
                    .catch(error => {
                        console.error('Error adding role:', error);
                        alert('Error adding role: ' + error.message);
                    });
            } else if (formType === 'EDIT' ) {
                console.log(branchId)
                axios.put(`${apiUrl}/role_permission/`, roleData)
                    .then(() => {
                        alert('Role updated successfully');
                        resetStates();
                        setFormType('ADD');
                    })
                    .catch(error => {
                        alert('Error updating role: ' + error.message);
                    });
            }
        }
    };

    const resetStates = () => {
        setSelectedRole([]);
        setMenuContent(dynamicModules);
        setId(null);
        setRoleError('');
        setBranchId('');
    };

    return (
        <Main>
            <PageContainer title="Role Permission">
                <Card title="">
                    <div className="row mb-4">
                        <div className="col-sm-6 col-md-4 col-lg-3">
                            <label>Select Role</label>
                            <Select
                                options={dropDownList.filter(dl => dl.category === 'Role').map((dd) => ({ label: dd.title, value: dd.title }))}
                                placeholder="Select Role"
                                isMulti
                                value={selectedRole}
                                onChange={handleRoleChange}
                            />
                            {roleError && <div className="text-danger">{roleError}</div>}
                        </div>
                        <div className='col-sm-6 col-md-4 col-lg-3'>
                            <FormInput
                                label='Branch'
                                name='Branch'
                                labelClassName="required"
                                type='select'
                                value={branchId}
                                onChange={(e) => setBranchId(e.target.value)}
                                errorText={branchIdError}
                            >
                                <option value=''>Select</option>
                                {branchList.map(branch => (
                                    <option key={branch.id} value={branch.id}>{branch.name}</option>
                                ))}
                            </FormInput>
                        </div>

                    </div>

                    <h4>Assign Permissions</h4>
                    {menuContent.length > 0 ? (
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Menu</th>
                                    <th>View</th>
                                    <th>Add</th>
                                    <th>Edit</th>
                                    <th>Delete</th>
                                </tr>
                            </thead>
                            <tbody>
                                {menuContent.map(menu => (
                                    <tr key={menu.pageTitle}>
                                        <td>{menu.pageTitle}</td>
                                        <td>
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                checked={menu.view}
                                                onChange={(e) => handlePermissionChange(menu.pageTitle, 'view', e.target.checked)}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                checked={menu.add}
                                                onChange={(e) => handlePermissionChange(menu.pageTitle, 'add', e.target.checked)}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                checked={menu.edit}
                                                onChange={(e) => handlePermissionChange(menu.pageTitle, 'edit', e.target.checked)}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                checked={menu.delete}
                                                onChange={(e) => handlePermissionChange(menu.pageTitle, 'delete', e.target.checked)}
                                            />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    ) : (
                        <p>No menu content available.</p>
                    )}
                    <>
                        {(formType === 'ADD' || formType === 'EDIT') && (
                            <div className="d-flex justify-content-end hstack gap-1 mt-2">
                                <button className="btn btn-sm fw-bold btn-secondary" onClick={resetStates} style={{marginRight: "5px"}}>Clear</button>
                                <button className="btn btn-sm fw-bold btn-primary" onClick={handleSave} style={{ backgroundColor: "#10296C", color: "#fff" }}>{formType === 'ADD' ? "Save" : "Update"}</button>
                            </div>
                        )}
                    </>
                </Card>
            </PageContainer>
        </Main>
    );
};

export default RoleManagement;
