import React, { useState, useEffect } from 'react'
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Main from '../components/custom/Main'
import PageContainer from '../components/custom/PageContainer'
import Card from '../components/custom/Card'
import { apiUrl } from '../config';
import CollapseCard from '../components/custom/CollapseCard';
import "../styles/Table.css"
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../redux/store';
import { StoreState } from '../redux/reducers';
import { addBranch, BranchType, deleteBranch, fetchBranch, updateBranch } from '../redux/actions/branchActions';

const Branch = () => {
    const dispatch = useDispatch<AppDispatch>()
    const branchList = useSelector<StoreState, BranchType[]>(state => state.branch)
    const [editFlag, setEditFlag] = useState(false);
    const [id, setId] = useState<number>(0);
    const [name, setName] = useState<string>("");
    const [affiliatedUniversity, setAffiliatedUniversity] = useState<string>("");
    const [affiliationNo, setAffiliationNo] = useState<string>("");
    const [place, setPlace] = useState<string>("");
    const [photoUrl, setPhotoUrl] = useState<any | null>({ name: '' });
    const [oldPhotoUrl, setOldPhotoUrl] = useState('');

    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

    const [nameError, setNameError] = useState<string>("");
    const [affiliatedUniversityError, setAffiliatedUniversityError] = useState<string>("");
    const [affiliationNoError, setAffiliationNoError] = useState<string>("");
    const [placeError, setPlaceError] = useState<string>("");
    const [photoUrlError, setPhotoUrlError] = useState<string>("");

    useEffect(() => {
        dispatch(fetchBranch())
    }, [])

    const clearHandler = () => {
        setName("");
        setAffiliatedUniversity("");
        setAffiliationNo("");
        setPlace("");
        setPhotoUrl({ name: '' });
        setOldPhotoUrl('');

        setNameError("");
        setAffiliatedUniversityError("");
        setAffiliationNoError("");
        setPlaceError("");
        setPhotoUrlError("");

        setEditFlag(false);
    };

    const addHandler = () => {
        let error = false;

        if (name === "") {
            setNameError('Name is required');
            error = true;
        } else if (name.length > 50) {
            setNameError("Name must be within 50 characters");
            error = true;
        }

        if (affiliatedUniversity === "") {
            setAffiliatedUniversityError('Affiliated University is required');
            error = true;
        } else if (affiliatedUniversity.length > 100) {
            setAffiliatedUniversityError("Affiliated University must be within 100 characters");
            error = true;
        }

        if (affiliationNo === "") {
            setAffiliationNoError('Affiliation No is required');
            error = true;
        } else if (affiliationNo.length > 50) {
            setAffiliationNoError("Affiliation No must be within 50 characters");
            error = true;
        }

        if (place === "") {
            setPlaceError('Place is required');
            error = true;
        } else if (place.length > 100) {
            setPlaceError("Place must be within 100 characters");
            error = true;
        }


        if (photoUrl.name === '') {
            setPhotoUrlError('Logo is required');
            error = true;
        }
        

        const data = new FormData();

        data.append('name', name.toUpperCase());
        data.append('affiliatedUniversity', affiliatedUniversity);
        data.append('affiliationNo', affiliationNo);
        data.append('place', place);

        if (photoUrl && photoUrl.name) {
            data.append('photoUrl', photoUrl);
        } else if (oldPhotoUrl) {
            data.append('photoUrl', oldPhotoUrl);
        }

        if (!error) {
            dispatch(addBranch(data)).then(text => {
                toast.success(text)
                dispatch(fetchBranch())
                clearHandler();
            }).catch(text => {
                toast.error(text)
            })
        }
    };

    const editHandler = (id: number, photoUrl: string, name: string, affiliatedUniversity: string, affiliationNo: string, place: string) => {
        setEditFlag(true);
        setId(id);
        setName(name);
        setAffiliatedUniversity(affiliatedUniversity);
        setAffiliationNo(affiliationNo);
        setPlace(place);
        setPhotoUrl({ name: photoUrl })
    };

    const updateHandler = () => {
        let error = false;

        if (name === "") {
            setNameError('Name is required');
            error = true;
        } else if (name.length > 50) {
            setNameError("Name must be within 50 characters");
            error = true;
        }

        if (affiliatedUniversity === "") {
            setAffiliatedUniversityError('Affiliated University is required');
            error = true;
        } else if (affiliatedUniversity.length > 100) {
            setAffiliatedUniversityError("Affiliated University must be within 100 characters");
            error = true;
        }

        if (affiliationNo === "") {
            setAffiliationNoError('Affiliation No is required');
            error = true;
        } else if (affiliationNo.length > 50) {
            setAffiliationNoError("Affiliation No must be within 50 characters");
            error = true;
        }

        if (place === "") {
            setPlaceError('Place is required');
            error = true;
        } else if (place.length > 100) {
            setPlaceError("Place must be within 100 characters");
            error = true;
        }

        if (photoUrl.name === '') {
            setPhotoUrlError('Logo is required');
            error = true;
        }

        const data = new FormData();
        data.append('name', name.toUpperCase());
        data.append('affiliatedUniversity', affiliatedUniversity);
        data.append('affiliationNo', affiliationNo);
        data.append('place', place);

        if (photoUrl && photoUrl.name) {
            data.append('photoUrl', photoUrl);
        } else if (oldPhotoUrl) {
            data.append('photoUrl', oldPhotoUrl);
        }

        if (!error) {
            dispatch(updateBranch(data, id)).then(text => {
                toast.success(text)
                dispatch(fetchBranch())
                clearHandler();
            }).catch(text => {
                toast.error(text)
            })
        }
    };

    const openDeleteConfirmation = () => {
        setShowDeleteConfirmation(true);
    };

    const closeDeleteConfirmation = () => {
        setShowDeleteConfirmation(false);
    };

    const deleteHandler = (id: number) => {
        setId(id);
        openDeleteConfirmation();
    };

    const confirmDeleteHandler = () => {
        if (id !== undefined) {
            dispatch(deleteBranch(id)).then(text => {
                toast.success(text)
                dispatch(fetchBranch())
                closeDeleteConfirmation();
            }).catch(text => {
                toast.error(text);
            })
        }
    };

    return (
        <Main>
            <PageContainer title="Branch">
                <Card title={editFlag ? "Edit Branch" : "Add Branch"}>
                    <div className="row">
                        <div className='col-sm-6 col-md-4 col-lg-3'>
                            <label className="mt-3 mb-3">Name <span className="text-danger">*</span> : </label>
                            <input className="form-control text-uppercase" placeholder="Enter name..." type="text" onChange={(e) => {
                                setName(e.target.value);
                            }} value={name} />
                            {nameError && <div className="text-danger">{nameError}</div>}
                        </div>
                        <div className='col-sm-6 col-md-4 col-lg-3'>
                            <label className="mt-3 mb-3">Affiliated University <span className="text-danger">*</span> : </label>
                            <input className="form-control" placeholder="Enter university..." type="text" onChange={(e) => {
                                setAffiliatedUniversity(e.target.value);
                            }} value={affiliatedUniversity} />
                            {affiliatedUniversityError && <div className="text-danger">{affiliatedUniversityError}</div>}
                        </div>
                        <div className='col-sm-6 col-md-4 col-lg-3'>
                            <label className="mt-3 mb-3">Affiliation No <span className="text-danger">*</span> : </label>
                            <input className="form-control" placeholder="Enter affiliation no..." type="text" onChange={(e) => {
                                setAffiliationNo(e.target.value);
                            }} value={affiliationNo} />
                            {affiliationNoError && <div className="text-danger">{affiliationNoError}</div>}
                        </div>
                        <div className='col-sm-6 col-md-4 col-lg-3'>
                            <label className="mt-3 mb-3">Place <span className="text-danger">*</span> : </label>
                            <input className="form-control" placeholder="Enter place..." type="text" onChange={(e) => {
                                setPlace(e.target.value);
                            }} value={place} />
                            {placeError && <div className="text-danger">{placeError}</div>}
                        </div>
                        <div className='col-sm-6 col-md-4 col-lg-3 custom-file'>
                            <label className="mt-3 mb-3">Logo <span className="text-danger">*</span> : </label>
                            <div className="input-group">
                                <div className="custom-file">
                                    <input
                                        type="file"
                                        className="custom-file-input"
                                        placeholder="Enter photo Url..."
                                        name="photoUrl"
                                        onChange={(e) => {
                                            const selectedFile = e.target.files ? e.target.files[0] : null;
                                            if (selectedFile) {
                                                setPhotoUrl(selectedFile);
                                            } else {
                                                console.log('No file selected.');
                                            };
                                        }}
                                        aria-describedby="inputGroupFileAddon01"
                                    // value={photoUrl}
                                    />
                                    <label className="custom-file-label" htmlFor="inputGroupFile01">
                                        {photoUrl.name !== "" ? photoUrl.name : 'Choose file...'}
                                    </label>
                                </div>
                            </div>

                            {photoUrlError && <div className="text-danger">{photoUrlError}</div>}
                        </div>
                    </div>
                    <div className="m-4 d-flex justify-content-end">
                        <button className="btn btn-sm btn-secondary" onClick={clearHandler} style={{ marginRight: '10px' }}>Clear</button>
                        {editFlag ? <button className="btn btn-sm" onClick={updateHandler} style={{ backgroundColor: "#10296C", color: "#fff" }}>Update</button> :
                            <button className="btn btn-sm" onClick={addHandler} style={{ backgroundColor: "#10296C", color: "#fff" }}>Add</button>}
                    </div>
                </Card>
                <CollapseCard title="Branch List">
                    <div className="container-fluid" style={{ width: "100%" }}>
                        <table className="table table-striped">
                            <thead style={{ backgroundColor: "#10296C", color: "#fff" }}>
                                <tr>
                                    <th>S.No</th>
                                    <th>Logo</th>
                                    <th>Name</th>
                                    <th>Affiliated University</th>
                                    <th>Affiliation No</th>
                                    <th>Place</th>
                                    <th>Options</th>
                                </tr>
                            </thead>
                            <tbody>
                                {branchList.map((branch, index) => (
                                    <tr key={branch.id}>
                                        <td>{index + 1}</td>
                                        <td><img src={apiUrl + "/" + branch.photoUrl} alt={branch.name} className="student-thumbnail" /></td>
                                        <td>{branch.name}</td>
                                        <td>{branch.affiliatedUniversity}</td>
                                        <td>{branch.affiliationNo}</td>
                                        <td>{branch.place}</td>
                                        <td>
                                            <button className="btn btn-sm btn-primary" style={{ marginRight: '10px', backgroundColor: '#10296C' }} onClick={() => editHandler(branch.id, branch.photoUrl, branch.name, branch.affiliatedUniversity, branch.affiliationNo, branch.place)}>Edit</button>
                                            <button className="btn btn-sm btn-danger" onClick={() => deleteHandler(branch.id)}>Delete</button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </CollapseCard>
            </PageContainer>
            <Modal show={showDeleteConfirmation} onHide={closeDeleteConfirmation}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete this branch?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeDeleteConfirmation}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={confirmDeleteHandler}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
            <ToastContainer />
        </Main>
    )
}

export default Branch;
