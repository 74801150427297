import React, { FC } from "react";
import { Link } from 'react-router-dom';
const TopNavBar: FC = () => {
  return (
    <nav className="main-header navbar navbar-expand navbar-light fixed-top">
      <ul className="navbar-nav">
        <li className="nav-item">
          <Link className="nav-link" data-widget="pushmenu" to="#" role="button"><i className="fas fa-bars" style={{ color: "#10296C" }}></i></Link>
        </li>
      </ul>
      <img
        src={process.env.PUBLIC_URL ? process.env.PUBLIC_URL + "img/college_logo.png" : "../img/college_logo.png"}
        width="50px"
        alt="College Logo"
      />
      <h5 style={{ textTransform: "uppercase", color: "#10296C", marginLeft: "5px" }} className="mt-2">Bharath College</h5>

      <ul className="navbar-nav ml-auto">

        <li className="nav-item">
          <a className="nav-link" data-widget="fullscreen" href="#" role="button">
            <i className="fas fa-expand-arrows-alt" style={{ color: "#10296C" }}></i>
          </a>
        </li>
      </ul>
    </nav>
  )
}
export default TopNavBar;