import React, { FC } from "react";

const Footer:FC = () =>{
    const currentYear = new Date().getFullYear();
    return(
    <footer className="main-footer">
        <div className="float-right d-none d-sm-block">
            Copyright &copy; {currentYear}. All rights reserved.
        </div>
        Powered By <a href="http://www.mindzcube.com"><img src={process.env.PUBLIC_URL + "img/mindzcube.png"} height="30px" alt=""/></a>
    </footer>
    )
}
export default Footer;