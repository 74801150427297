import axios, { AxiosRequestConfig } from 'axios';
import { apiUrl } from '.';

export interface APIResponse<D = undefined> {
    data?: D;
    message?: string;
    error?: string;
}


const api = axios.create({ baseURL: apiUrl });


export const getAxiosRequestConfig = (token: string): AxiosRequestConfig => {
    return {
        headers: {
            Authorization: token
        }
    }
};

export const errorHandler = (error: any, errorText?: string) => {
    const defaultText = 'Unable to process request';
    if (error.code && error.code === 'ERR_NETWORK') {
        return String(error.message
            ? error.message
            : errorText
                ? errorText
                : defaultText);
    } else if (error.response) {
        return String(error.response.data
            ? error.response.data.message
            : errorText
                ? errorText
                : defaultText);
    } else {
        return String(error.message
            ? error.message
            : errorText
                ? errorText
                : defaultText);
    }
};

export default api;
