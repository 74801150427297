import { combineReducers } from "redux";
import { Action } from "../actions/types";
import dropdownReducer from "./dropdownReducer";
import authUserReducer from "./authUserReducer";
import userReducer from "./userReducer";
import branchReducer from "./branchReducer";

// Combine reducers
const combinedReducers = combineReducers({
    authUser: authUserReducer,
    user: userReducer,
    branch: branchReducer,
    dropDown: dropdownReducer,
});

// Reducer function
const reducers = (state: StoreState | undefined, action: Action) => {
    return combinedReducers(state, action);
};

// Types
export type StoreState = ReturnType<typeof combinedReducers>;
export type GetState = () => StoreState;

export default reducers;
